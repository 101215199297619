import React from 'react';
import { Row, Col, Grid } from 'react-flexbox-grid';
import UnsubscribeForm from './Form.js';
import './App.css';

function App() {
  return (
    <Grid className={"card"}>
      <Row center="xs">
        <Col xs={12} sm={12} md={12} lg={12}>
          <h1 className="headline">BeenVerified Unsubscribe</h1>
        </Col>
      </Row>
      <Row center="xs">
        <Col xs={12} sm={12} md={12} lg={12}>
          <p>To unsubscribe, please enter your email address below.</p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <UnsubscribeForm />
        </Col>
      </Row>
    </Grid>
  );
}

export default App;
